let mode = localStorage.getItem('mode') || 'light';

export const customStyles = {
    pagination: {
        style: {
            backgroundColor: 'transparent',
        },
        pageButtonsStyle: {
            color: mode === 'dark' ? 'white' : 'black', // Adjust color as needed for better visibility
        },
    },
};
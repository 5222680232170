import axios from "axios"
import config from "../config/index"



export const getPlanDetails = async () => {
    try {
        let resp = await axios({
            method: "get",
            url: `${config.Server_URL}routesUser/getPlanDetails`,
        });
        return {
            data: resp?.data,
        };
    } catch (err) {
    }
}


export const seaarchUserTree = async (data) => {
    try {
        // console.log(data, "datadata");
        let respData = await axios({
            method: "post",
            url: `${config.Server_URL}api/routesUser/searchUser`,
            data: data
        });
        return {
            user: respData.data,
            status: respData.data.status
        };
    } catch (err) {
        return {
            loading: false,
            error: err
        };
    }
};




export const getUserDetails = async (payload) => {
    try {
        let resp = await axios({
            method: "get",
            url: `${config.Server_URL}api/routesUser/getUserDetails`,
            params: payload,
        });
        return {
            data: resp?.data,
            // royaltyData: resp?.royaltyData
        };
    } catch (err) {
    }
}

export const getSignature = async (data) => {
    try {
        let resp = await axios({
            method: "post",
            url: `${config.Server_URL}api/routesUser/getSignature`,
            data: data
        });
        return {
            data: resp.data
        };
    } catch (err) {
        console.log(err);
    }
};


export const CheckTransHash = async (payload) => {
    try {
        let resp = await axios({
            method: "get",
            url: `${config.Server_URL}api/routesUser/CheckTransHash`,
            params: payload
        });
        console.log(resp);
        return {
            data: resp?.data,
        };
    } catch (err) {

    }
}


export const royaltyClaim = async (data) => {
    try {
        let resp = await axios({
            method: "post",
            url: `${config.Server_URL}api/routesUser/royaltyClaim`,
            data: data
        });
        return {
            data: resp.data
        };
    } catch (err) {
        console.log(err);
    }
};

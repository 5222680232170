import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  InstapaperShareButton,
} from "react-share";
import config from "../config/index.js";
import { useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert.js";
import copy from "copy-to-clipboard";
import {
  faFacebookF,
  faTelegramPlane,
  faTwitter,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import { Col, Container, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
function ShareLink() {
  const walletConnection = useSelector((state) => state?.walletConnection);

  async function copyToClipboard(clipdata) {
    copy(clipdata);
    toastAlert("success", "Copied Successfully");
  }

  return (
    <>
      <Row>
        <Col lg="6">
          {" "}
          <div className="Referral_Link_left">
            <h6>Left Referral Link</h6>

            <Form.Group className="linkbox-card">
              <InputGroup className="text_box_1 text-Referral  ">
                <Form.Control
                  placeholder="https://VForce.biz/i/g9..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="custom_placeholder "
                  Value={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                />
                <InputGroup.Text id="basic-addon2 " className="deposit_box-1">
                  <img
                    src={require("../assets/images/dashboard/copy.png")}
                    className="img-fluid copy-img "
                    onClick={() =>
                      copyToClipboard(
                        `${config.frontUrl}join-now/${walletConnection.address}/1`
                      )
                    }
                  />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Dropdown className="share_icon_img">
              <Dropdown.Toggle id="dropdown-basic">
                <img
                  src={require("../assets/images/share_icon.png")}
                  width={20}
                  height={20}
                  className="img-fluid "
                />{" "}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  <FacebookShareButton
                    url={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faFacebookF} />
                  </FacebookShareButton>
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                  {" "}
                  <TelegramShareButton
                    url={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                  >
                    <FontAwesomeIcon icon={faTelegramPlane} />
                  </TelegramShareButton>
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  <TwitterShareButton
                    url={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </TwitterShareButton>
                </Dropdown.Item>

                <Dropdown.Item href="#/action-4">
                  <WhatsappShareButton
                    url={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                  >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </WhatsappShareButton>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <ul className="footer_panel_right-ul footer_panel_right-ul_1  mt-3">
            <li>
              <a>
                <FacebookShareButton
                  url={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                >
                  {" "}
                  <FontAwesomeIcon icon={faFacebookF} />
                </FacebookShareButton>
              </a>
            </li>

            <li>
              <a>
                <TelegramShareButton
                  url={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                >
                  <FontAwesomeIcon icon={faTelegramPlane} />
                </TelegramShareButton>
              </a>
            </li>
            <li>
              <a>
                <TwitterShareButton
                  url={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </TwitterShareButton>
              </a>
            </li>
            <li>
              <a>
                <WhatsappShareButton
                  url={`${config.frontUrl}join-now/${walletConnection.address}/1`}
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                </WhatsappShareButton>
              </a>
            </li>
          </ul> */}
          </div>
        </Col>
        <Col lg="6">
          {" "}
          <div className="Referral_Link_left">
            <h6>Right Referral Link</h6>

            <Form.Group className="linkbox-card">
              <InputGroup className="text_box_1 text-Referral  ">
                <Form.Control
                  placeholder="https://VForce.biz/i/g9..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="custom_placeholder "
                  Value={`${config.frontUrl}join-now/${walletConnection.address}/2`}
                />
                <InputGroup.Text id="basic-addon2 " className="deposit_box-1">
                  <img
                    src={require("../assets/images/dashboard/copy.png")}
                    className="img-fluid copy-img "
                    onClick={() =>
                      copyToClipboard(
                        `${config.frontUrl}join-now/${walletConnection.address}/2`
                      )
                    }
                  />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Dropdown className="share_icon_img">
              <Dropdown.Toggle id="dropdown-basic">
                <img
                  src={require("../assets/images/share_icon.png")}
                  width={20}
                  height={20}
                  className="img-fluid "
                />{" "}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  <FacebookShareButton
                    url={`${config.frontUrl}join-now/${walletConnection.address}/2`}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faFacebookF} />
                  </FacebookShareButton>
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                  {" "}
                  <TelegramShareButton
                    url={`${config.frontUrl}join-now/${walletConnection.address}/2`}
                  >
                    <FontAwesomeIcon icon={faTelegramPlane} />
                  </TelegramShareButton>
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  <TwitterShareButton
                    url={`${config.frontUrl}join-now/${walletConnection.address}/2`}
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </TwitterShareButton>
                </Dropdown.Item>

                <Dropdown.Item href="#/action-4">
                  <WhatsappShareButton
                    url={`${config.frontUrl}join-now/${walletConnection.address}/2`}
                  >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </WhatsappShareButton>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ShareLink;

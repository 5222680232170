import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import config from "../../config/index";

import PropagateLoader from "react-spinners/PropagateLoader";

import TokenABI from "../../ABI/TokenContractAbi.json";
import ContractAbi from "../../ABI/MLMContractAbi.json";

import { connection } from "../../helper/connection";
import { toastAlert } from "../../helper/toastAlert";
import {
  getSignature,
  WithdrawSave,
  CancelWithdrawSave,
} from "../../Action/api.Action";
import { convert } from '../../helper/convert';
import { useNavigate } from "react-router-dom";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

function DashBarDetail(props) {
  const { UserData, type, settings } = props;

  console.log(UserData, "UserDatatype");

  const walletConnection = useSelector((state) => state.walletConnection);
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [tokenprice, settokenprice] = useState(0);
  const [minWithdraw, setminWithdraw] = useState(0);

  function generateRandomSixDigitNumber() {
    const min = 100000; // Smallest 6-digit number (100000)
    const max = 999999; // Largest 6-digit number (999999)
    // Generate a random number between min and max (inclusive)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  }

  // useEffect(() => {
  //     getPriceData()
  // }, [])

  const getPriceData = async () => {
    try {
      console.log(walletConnection, "walletConnection");

      if (walletConnection.address) {
        var web3 = new Web3(walletConnection?.web3);
        const Contract = new web3.eth.Contract(ContractAbi, config.Contract);
        let getTokenprice = await Contract.methods.tokenPrice().call();
        let getminWithdraw = await Contract.methods.minWithdrawLimit().call();

        console.log(getTokenprice, getminWithdraw, "kkk");
        setminWithdraw(getminWithdraw);
        settokenprice(getTokenprice / 1e18);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleWithdraw = async (amount) => {
    try {
      if (type == "check") {
        toastAlert("warning", "You can't do Anthing Here :)");
        return false;
      }

      setLoader(true);
      if (walletConnection.address) {
        if (amount == 0) {
          toastAlert("error", "Amount must be greater than zero");
          setLoader(false);
          return false;
        }
        if (Number(amount) <= Number(minWithdraw)) {
          toastAlert(
            "error",
            `Amount must be greater than Minimum Withdrawal $${minWithdraw}`
          );
          setLoader(false);
          return false;
        }

        var web3 = new Web3(walletConnection?.web3);

        let bnbBal = await web3.eth.getBalance(walletConnection.address);
        bnbBal = bnbBal / config.decimalVal;
        const Contract = new web3.eth.Contract(ContractAbi, config.Contract);
        let isBlock = await Contract.methods
          .isBlocked(walletConnection.address)
          .call();

        if (isBlock) {
          toastAlert("error", "Your Blocked by admin");
          setLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }
        const randomSixDigitNumber = await generateRandomSixDigitNumber();
        const payload = {
          address: walletConnection.address,
          amount: amount,
          randNumber: randomSixDigitNumber,
          tokenprice: tokenprice,
        };
        const getSign = await getSignature(payload);
        if (getSign?.data?.status == false) {
          toastAlert("error", getSign?.data?.message);
          setLoader(false);
          return false;
        }

        const signData = getSign?.data?.result?.signature;
        const Amount = getSign?.data?.result?.amount;

        var amt = (Number(Amount) * 1e18).toString()
        amt = await convert(amt);

        let estimateGas = await Contract.methods
          .withdraw(
            amt,
            walletConnection.address,
            randomSixDigitNumber,
            signData,
            0
          )
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));
        let gasPricebusd = await web3.eth.getGasPrice();
        let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
        if (estimateGasFee > bnbBal) {
          toastAlert("error", "Your BNB balance is low");
          setLoader(false);
          return false;
        }

        await Contract.methods
          .withdraw(
            amt,
            walletConnection.address,
            randomSixDigitNumber,
            signData,
            0
          )
          .send({ from: walletConnection.address })
          .then(async (res) => {
            if (res && res.status && res.transactionHash) {
              // const payload = {
              //     sig: signData,
              //     _user: walletConnection.address,
              //     hash: res.transactionHash
              // };
              // const getWith = await WithdrawSave(payload);
              // if (getWith?.data?.status) {
              toastAlert("success", "Withdraw Successfully");
              setTimeout(() => {
                window.location.reload();
              }, 4000);
              // setLoader(false);
              // } else {
              //     toastAlert("error", "Something Wrong");
              // }
            }
          })
          .catch((err) => {
            toastAlert("error", "Withdraw Rejected");
            setTimeout(() => {
              window.location.reload();
            }, 4000);
            // setLoader(false);
          });
      } else {
        toastAlert("error", "Please connect wallet");
        setLoader(false);
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(tokenprice, "tokenprice");

  return (
    <>
      {" "}
      <div className="dashborad_content dashborad_content_info">
        <div className="dashboard_info dashboard_info_details">
          <img
            src={require("../../assets/images/dashboard/dti1.png")}
            className="img-fluid"
          />
          <div className="dashboard_text">
            <h3>{walletConnection?.userdetails?.id}</h3>
            <p>My Id</p>
          </div>
        </div>
        {/* <div className="dashboard_info">
                <img
                    src={require("../../assets/images/dashboard/dti1.png")}
                    className="img-fluid"
                />
                <div className="dashboard_text">
                    <h3>{UserData?.parentID}</h3>
                    <p>Parent Id</p>
                </div>
            </div> */}
        <div className="dashboard_info dashboard_info_details">
          <img
            src={require("../../assets/images/dashboard/dti2.png")}
            className="img-fluid"
          />
          <div className="dashboard_text">
            <h3>$ {(UserData?.totalIncome || 0).toFixed(4)}</h3>
            <p>Received Balance</p>
          </div>
        </div>
        <div className="dashboard_info dashboard_info_details">
          <img
            src={require("../../assets/images/dashboard/dti3.png")}
            className="img-fluid"
          />
          <div className="dashboard_text">
            <h3>
              ${" "}
              {(
                (UserData?.totalIncome || 0) -
                (UserData?.withdrawtotalIncome || 0)
              ).toFixed(4)}
            </h3>
            <p>Total Withdraw</p>

            {!walletConnection?.userdetails?.isBlocked && (
              <button
                className="primary_btn"
                data-bs-toggle="modal"
                data-bs-target="#WithdrawConfirmation_popUp"
                onClick={getPriceData}
              >
                Withdraw
              </button>
            )}
          </div>
        </div>
        <div className="dashboard_info dashboard_info_details">
          <img
            src={require("../../assets/images/dashboard/dti3.png")}
            className="img-fluid"
          />
          <div className="dashboard_text">
            <h3> $ {(UserData?.withdrawtotalIncome || 0).toFixed(4)}</h3>
            <p>Withdraw $</p>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#eba6fb"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div
        className="modal fade primary_modal"
        id="WithdrawConfirmation_popUp"
        tabIndex={-1}
        aria-labelledby="WithdrawConfirmation_popUp"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="WithdrawConfirmation_popUp">
                Info
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {/* <button onClick={() => upgradePlan(SelectedPlan)} className="primary_btn mt-1">
                                        Confirm
                                    </button> */}

                  <div className="text-center popupPara">
                    {/* <h5 className="modal-title" id="Rejoin_popUp">
                                            price * Token price = Plan price
                                        </h5>
                                        <h5 className="modal-title" id="Rejoin_popUp">
                                            {UserData?.totalIncome} * {tokenprice} = {UserData?.totalIncome * tokenprice}
                                        </h5> */}
                    Note* : your withdraw balance is{" "}
                    {(
                      (((UserData && UserData?.totalIncome) || 0) -
                        ((UserData && UserData?.withdrawtotalIncome) || 0)) *
                      tokenprice
                    ).toFixed(4)}{" "}
                    YEZB Token
                  </div>

                  <button
                    className="primary_btn mt-4 mx-auto d-block"
                    onClick={() =>
                      handleWithdraw(
                        (UserData?.totalIncome || 0) -
                          (UserData?.withdrawtotalIncome || 0)
                      )
                    }
                  >
                    Withdraw
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashBarDetail;

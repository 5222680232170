import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTelegramPlane,
  faTwitter,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import config from "../config/index";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_right">
            <div className=" align-items-center">
              <div className="logo-img">
                <img
                  src={require("../assets/images/logo.png")}
                  className="img-fluid"
                />{" "}
              </div>
              <div className="footer_panel_right1">
                <p className="ptag1">
                  The world's first 100% Decentralized matrix platform
                </p>
                {/* <div>
                  <p className="ptag2">Smart-contract address:</p>
                  <a
                    className="ptag1"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    href={`${config.bscscan}address/${config.Contract}`}
                  >
                    {config.Contract}
                  </a>
                </div> */}
                <ul className="footer_panel_right-ul">
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faTelegramPlane} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                  </li>
                </ul>
              </div>
              <hr></hr>
              <div>
                <p className="text-center ptag3">
                  Copyrights © 2024, VForrce, All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

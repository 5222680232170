import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { royaltyClaim } from "../../Action/api.Action"
// import { customStyles } from '../../helper/paginationcolor';
import { toastAlert } from "../../helper/toastAlert";
import PropagateLoader from "react-spinners/PropagateLoader";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};


function RoyaltyHistory({ Royaltylist, User }) {



  const [loader, setLoader] = useState(false);

  const checkStatus = (rank) => {
    const finalData = User?.royaltyclaimHistory?.find(
      (el) => el.rank == rank
    );
    return finalData?.status
  }




  const columns = useMemo(
    () => [
      {
        name: "Rank",
        selector: (row) => row.rank == "star" ? "Star" : `${row.rank} Star`,
        width: "10%",
      },
      {
        name: "P-Value",
        selector: (row) => row.personalVolume,
        width: "140px",
      },
      {
        name: "D-Value",
        selector: (row) => row.directVolume,
        width: "140px",
      },
      {
        name: "S-Leg Value",
        selector: (row) => row.sleg,
        width: "170px",
      },
      {
        name: "M-Leg Value",
        selector: (row) => row.mleg,
        width: "170px",
      },
      {
        name: "Rewards Token",
        selector: (row) => row.rewardToken,
        width: "190px",
      },
      {
        name: "Rewards",
        selector: (row) => row.Rewards,
        width: "140px",
      },
      {
        name: "Achieved",
        selector: (row) => {
          const rowRank = row.rank === "star" ? 1 : Number(row.rank) + 1;
          return rowRank == User?.currentRank ? "Achieved" : checkStatus(row.rank) !== undefined ? "Achieved" : "Nil";
        },
        width: "140px",
      },
      {
        name: "Received",
        width: "170px",
        selector: (row) => {
          const rowRank = row.rank === "star" ? 1 : Number(row.rank) + 1;
          const status = checkStatus(row.rank);

          if (rowRank == User?.currentRank) {
            if (status === 0) return "Requested";
            if (status === 1) return "Completed";
            return (
              <button
                className="primary_btn mt-2"
                disabled={loader}
                onClick={() => UserClaimRoyality(User?.userID, row.rewardToken, row._id, row.rank)}
              >
                Claim
              </button>
            );
          }

          if (status !== undefined) {
            if (status === 0) return "Requested";
            if (status === 1) return "Completed";
            return (
              <button
                className="primary_btn mt-2"
                disabled={loader}
                onClick={() => UserClaimRoyality(User?.userID, row.rewardToken, row._id, row.rank)}
              >
                Claim
              </button>
            );
          }

          return "Pending";
        },
      },
    ],
    [User]
  );


  const UserClaimRoyality = async (userID, amount, royaltyId, rank) => {
    try {
      setLoader(true)
      let reqdata = {
        userID, amount, royaltyId, rank, address: User.address
      }

      let sendReq = await royaltyClaim(reqdata)

      setLoader(true)
      if (sendReq) {
        toastAlert("success", "Your Claim Request Successfully Submitted");
        setLoader(true)
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }

    } catch (err) {

    } 
  }



  let mode = localStorage.getItem("mode");
  // console.log(mode, "modemode");

  const customStyles = {
    pagination: {
      style: {
        backgroundColor: "transparent",
      },
      pageButtonsStyle: {
        color: "white", // Adjust color as needed for better visibility
      },
    },
  };
  // console.log(customStyles, "customStyles");

  return (
    <>
      <DataTable
        columns={columns}
        data={Royaltylist}
        className="dash_board_table mt-3"
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10]}
        customStyles={customStyles}
      />
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#eba6fb"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}

export default RoyaltyHistory;

import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../helper/paginationcolor';
import moment from 'moment';

function LevelwiseHistory({ tradPassInceHistory }) {


    const groupAndCountUniqueFromUser = useMemo(() => {
        if (!tradPassInceHistory) return [];

        const groupedData = tradPassInceHistory.reduce((acc, item) => {
            const { level, planId, volume, percentage, amount, fromUser } = item;

            if (!acc[level]) {
                acc[level] = {
                    level,
                    uniqueFromUsers: new Set(),
                    totalAmount: 0,
                    userVolumes: {},
                    planId,
                    percentage
                };
            }

            acc[level].totalAmount += amount;

            if (!acc[level].userVolumes[fromUser]) {
                acc[level].userVolumes[fromUser] = 0;
                acc[level].uniqueFromUsers.add(fromUser);
            }

            acc[level].userVolumes[fromUser] += Number(volume);

            return acc;
        }, {});

        const result = Object.values(groupedData).map(group => {
            const totalVolumeForUniqueUsers = Object.values(group.userVolumes).reduce((sum, volume) => sum + volume, 0);
            return {
                level: group.level,
                uniqueFromUserCount: group.uniqueFromUsers.size,
                totalAmount: group.totalAmount,
                totalVolumeForUniqueUsers: totalVolumeForUniqueUsers,
                planId: group.planId,
                percentage: group.percentage
            };
        });
        return result;
    }, [tradPassInceHistory]);




    const columns = useMemo(() => [
        {
            name: 'Level',
            selector: row => row.level + 1,
            width: '120px',
        },
        {
            name: 'Team',
            selector: row => row.uniqueFromUserCount,
        },
        {
            name: 'Total Volume',
            selector: row => row.totalVolumeForUniqueUsers,
        },
        {
            name: 'Percentage',
            selector: row => row.percentage,
        },
        {
            name: 'Incentive',
            selector: row => row.totalAmount.toFixed(4),
        },
    ], []);



    return (
        <DataTable
            columns={columns}
            data={groupAndCountUniqueFromUser}
            className="dash_board_table mt-4"
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10]}
            customStyles={customStyles}
        />
    );
}

export default LevelwiseHistory;

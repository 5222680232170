import React, { useState, useEffect, memo } from "react";

import DataTable from "react-data-table-component";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import config from "../../config/index";
import { customStyles } from "../../helper/paginationcolor";

import PropagateLoader from "react-spinners/PropagateLoader";
import { getTransactionwithdraw } from "../../helper/checkhash"
import TokenABI from "../../ABI/TokenContractAbi.json";
import ContractAbi from "../../ABI/MLMContractAbi.json";

import { connection } from "../../helper/connection";
import { toastAlert } from "../../helper/toastAlert";
import { convert } from '../../helper/convert';
import {
  getSignature,
  WithdrawSave,
  CancelWithdrawSave,
} from "../../Action/api.Action";
import { useNavigate } from "react-router-dom";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

const WithdrawHistory = React.memo((props) => {
  const { withdrawHistory, type } = props;

  const walletConnection = useSelector((state) => state.walletConnection);
  const [loader, setLoader] = useState(false);
  const handleCancelWithdraw = async (
    amount,
    signData,
    randomSixDigitNumber
  ) => {
    try {
      if (type == "check") {
        toastAlert("warning", "You can't do Anthing Here :)");
        return false;
      }

      setLoader(true);
      if (walletConnection.address) {
        var web3 = new Web3(walletConnection?.web3);

        let bnbBal = await web3.eth.getBalance(walletConnection.address);
        bnbBal = bnbBal / config.decimalVal;

        const Contract = new web3.eth.Contract(ContractAbi, config.Contract);

        let isBlock = await Contract.methods
          .isBlocked(walletConnection.address)
          .call();

        if (isBlock) {
          toastAlert("error", "Your Blocked by admin");
          setLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }

        var amt = (Number(amount) * 1e18).toString()
        amt = await convert(amt);

        let estimateGas = await Contract.methods
          .cancelWithdraw(
            amt,
            walletConnection.address,
            randomSixDigitNumber,
            signData,
            0
          )
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));
        let gasPricebusd = await web3.eth.getGasPrice();
        let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
        if (estimateGasFee > bnbBal) {
          toastAlert("error", "Your BNB balance is low");
          setLoader(false);
          return false;
        }

        await Contract.methods
          .cancelWithdraw(
            amt,
            walletConnection.address,
            randomSixDigitNumber,
            signData,
            0
          )
          .send({ from: walletConnection.address })
          .then(async (res) => {
            if (res && res.status && res.transactionHash) {
              // const payload = {
              //     sig: signData,
              //     _user: walletConnection.address,
              //     hash: res.transactionHash
              // };
              const result = await getTransactionwithdraw(res.transactionHash);

              // const getWith = await getTransactionwithdraw(payload);
              // if (getWith?.data?.status) {
              if (result) {
                toastAlert("success", "Cancel Withdraw Successfully");
                // setLoader(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
              // } else {
              //     toastAlert("error", "Something Wrong");
              // }
            }
          })
          .catch((err) => {
            // setLoader(false);
            toastAlert("error", "Rejected cancel withdraw request");
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          });
      } else {
        toastAlert("error", "Please connect wallet");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const column_1 = [
    // {
    //     name: "ID",
    //     selector: (row, index) => index + 1,
    //     width: "10%",
    // },
    {
      name: "Date/Time",
      selector: (row) => moment(row?.createdAt).format("MMMM Do YYYY"),
    },
    {
      name: "Withdraw Amount (YEZB)",
      width: "240px",
      selector: (row) => (row.amount || 0) * (row.tokenprice || 0).toFixed(2),
    },
    {
      name: "Status",
      selector: (row) =>
        row?.Status == 1 ? "Rejected" : row?.Status == 2 ? "Success" : "Cancel",
    },
    {
      name: "Action",
      width: "190px",
      selector: (row) =>
        !walletConnection?.userdetails?.isBlocked && row?.Status == 1 ? (
          <button
            className="primary_btn mt-2"
            onClick={() => handleCancelWithdraw(row.amount, row.sig, row.nonce)}
          >
            Cancel
          </button>
        ) : (
          "-"
        ),
    },
  ];

  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      Status: "1988",
    },
  ];

  return (
    <>
      <DataTable
        columns={column_1}
        data={withdrawHistory}
        className="dash_board_table mt-3"
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10]}
        customStyles={customStyles}
      />
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#eba6fb"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
});

export default WithdrawHistory;

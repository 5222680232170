import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { useDispatch, useSelector } from "react-redux";
import { seaarchUserTree } from "../../Action/api.Action";
import { validPositive } from "../../helper/formatAddr";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

function TreeDisplay(props) {
  const { settings } = props;
  const walletConnection = useSelector((state) => state.walletConnection);
  const [binaryTree, setbinaryTree] = useState([]);
  const [srchLR, setSrchLR] = useState(false);
  const [searchId, setSearchId] = useState(walletConnection?.userdetails?.id);
  const [searchload, setsearchload] = useState(false);
  const [isvalid, setisvalid] = useState(false);

  console.log(walletConnection);

  useEffect(() => {
    searchClick();
  }, [walletConnection?.address, searchId]);

  const searchClick = async () => {
    setsearchload(true);
    let payload = {
      userId: searchId ? searchId : walletConnection?.userdetails?.id,
    };
    const data = await seaarchUserTree(payload);
    if (data?.user?.data[0]?.item["key"] == 0) {
      setisvalid(true);
    } else {
      console.log(data, "datadata");
      setbinaryTree(data);
      setisvalid(false);
    }
    setsearchload(false);
  };

  var userLevleft;
  var userLevright;

  var userLev1left = "Not Found";
  var userLev1right = "Not Found";

  var userLev2left = "Not Found";
  var userLev2right = "Not Found";


  var userLev1Lev1left = "Not Found";
  var userLev1Lev1right = "Not Found";

  var userLev1Lev2left = "Not Found";
  var userLev1Lev2right = "Not Found";


  var userLev2Lev1left = "Not Found";
  var userLev2Lev1right = "Not Found";

  var userLev2Lev2left = "Not Found";
  var userLev2Lev2right = "Not Found";



  const Findtotalleft = (userId) => {
    if (userId == 0 || userId == "Not Found") {
      return 0
    }

    const getdata = binaryTree &&
      binaryTree?.user &&
      binaryTree?.user?.data &&
      binaryTree?.user.data.find((item) => item.item.key == userId)
    return getdata?.item?.totalLeft
  }

  const Findtotalright = (userId) => {
    if (userId == 0 || userId == "Not Found") {
      return 0
    }
    const getdata = binaryTree &&
      binaryTree?.user &&
      binaryTree?.user?.data &&
      binaryTree?.user.data.find((item) => item.item.key == userId)
    return getdata?.item?.totalright
  }

  console.log(Findtotalleft(1), Findtotalright(1), "suryaprakash");

  const Funn = () => {
    for (let i = 0; i < binaryTree.length; i++) {
      if (binaryTree[i].items.user == searchId) {
        setSrchLR(true);
        break;
      }
    }
  };
  useEffect(() => {
    Funn();
  }, [searchId]);

  const searchchange = (e) => {
    var ValNum = parseInt(e.target.value);
    if (ValNum == null) {
      setSearchId(walletConnection?.userdetails?.id);
    } else {
      setSearchId(ValNum);
    }

    // alert(VaNum);
    for (let i = 0; i < binaryTree.length; i++) {
      if (binaryTree[i].items.key == ValNum) {
        setSrchLR(true);
        break;
      } else {
        setSrchLR(false);
      }
    }
  };




  return (
    <>
      <button class="btn d-block w-100 collapsed collapse-tree" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
        <h3 className="mb-0 text-start">My Tree</h3>
      </button>
      <div class="collapse" id="collapseExample">
        <div className="search_bar">
          <Form.Group>
            <InputGroup className="text_box_1 ">
              <Form.Control
                placeholder="Search ID"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className="custom_placeholder"
                onChange={searchchange}
                onInput={validPositive}
              />

              <InputGroup.Text id="basic-addon2" className="deposit_box-1">
                {searchload && (
                  <i
                    class="fa fa-spinner fa-spin checkspin"
                    style={{ fontSize: "22px" }}
                  ></i>
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          {/* <div class="black-box">
            <div class="black-box1">
              <p>
                Direct Referral
              </p>
              <h2>{settings?.directRefPer} %</h2>
            </div>
            <div class="black-box1">
              <p>
                Matching Referral Incentives
              </p>
              <h2>{settings?.MatchRefPer} %</h2>
            </div>
          </div> */}
        </div>
        <div className="main-tree">
          <Row>
            <Col lg={12}>
              <div class="tree">
                {!searchId ? (
                  <ul>
                    {binaryTree &&
                      binaryTree?.user &&
                      binaryTree?.user?.data &&
                      binaryTree?.user.data.map((item, index) => {
                        if (item.item.key == walletConnection?.userdetails?.id)
                          return (
                            <li>
                              <span style={{ display: "none" }}>
                                {
                                  (userLevleft =
                                    item.item.left == 0
                                      ? "Not Found"
                                      : item.item.left)
                                }{" "}
                                {
                                  (userLevright =
                                    item.item.right == 0
                                      ? "Not Found"
                                      : item.item.right)
                                }
                              </span>
                              <a
                                href="javascript:void(0)"
                                className="root_img"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`ID ${item.item.key || "Not Found"
                                  }  | L ${Findtotalleft(item.item.key)} | R ${Findtotalright(item.item.key)}`}
                                data-tooltip-place="top"
                              >
                                <img
                                  src={require("../../assets/images/dashboard/men_2.png")}
                                />
                              </a>
                              <Tooltip id="my-tooltip" />
                              <ul className="second_chat">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`ID ${userLevleft} | L ${Findtotalleft(userLevleft)} | R ${Findtotalright(userLevleft)}`}
                                    data-tooltip-place="top"
                                  >
                                    {" "}
                                    {userLevleft != "Not Found" ?
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      /> : <img
                                        src={require("../../assets/images/dashboard/disabledUser.png")}
                                      />}
                                  </a>

                                  <Tooltip id="my-tooltip" />

                                  <>
                                    {binaryTree &&
                                      binaryTree?.user &&
                                      binaryTree?.user?.data &&
                                      binaryTree?.user.data.map((item, index) => {
                                        if (
                                          userLevleft != 0 &&
                                          item.item.key != 0 &&
                                          item.item.key === userLevleft
                                        )
                                          return (
                                            <span style={{ display: "none" }}>
                                              {(userLev1left = item.item.left)}{" "}
                                              {(userLev1right = item.item.right)}
                                            </span>
                                          );
                                      })}
                                    <ul className="third_chat">
                                      {/* <> */}
                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content={`ID ${userLev1left == 0
                                            ? "Not Found"
                                            : userLev1left
                                            } | L ${Findtotalleft(userLev1left)} | R ${Findtotalright(userLev1left)}`}
                                          data-tooltip-place="top"
                                        >
                                          {" "}
                                          {userLev1left != 0 && userLev1left != "Not Found" ?
                                            <img
                                              src={require("../../assets/images/dashboard/chat_men.png")}
                                            /> : <img
                                              src={require("../../assets/images/dashboard/disabledUser.png")}
                                            />}
                                        </a>{" "}
                                        <Tooltip id="my-tooltip" />




                                        {binaryTree &&
                                          binaryTree?.user &&
                                          binaryTree?.user?.data &&
                                          binaryTree?.user.data.map((item, index) => {
                                            if (
                                              userLevleft != 0 &&
                                              item.item.key != 0 &&
                                              item.item.key === userLev1left
                                            )
                                              return (
                                                <span style={{ display: "none" }}>
                                                  {(userLev1Lev1left = item.item.left)}{" "}
                                                  {(userLev1Lev1right = item.item.right)}
                                                </span>
                                              );
                                          })}
                                        <ul className="third_chat">
                                          {/* <> */}
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content={`ID ${userLev1Lev1left == 0
                                                ? "Not Found"
                                                : userLev1Lev1left
                                                }  | L ${Findtotalleft(userLev1Lev1left)} | R ${Findtotalright(userLev1Lev1left)} `}
                                              data-tooltip-place="top"
                                            >
                                              {" "}


                                              {userLev1Lev1left != 0 && userLev1Lev1left != "Not Found" ?
                                                <img
                                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                                /> : <img
                                                  src={require("../../assets/images/dashboard/disabledUser.png")}
                                                />}

                                            </a>{" "}
                                            <Tooltip id="my-tooltip" />
                                          </li>

                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content={`ID ${userLev1Lev1right == 0
                                                ? "Not Found"
                                                : userLev1Lev1right
                                                } | L ${Findtotalleft(userLev1Lev1right)} | R ${Findtotalright(userLev1Lev1right)} `}
                                              data-tooltip-place="top"
                                            >
                                              {" "}

                                              {userLev1Lev1right != 0 && userLev1Lev1right != "Not Found" ?
                                                <img
                                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                                /> : <img
                                                  src={require("../../assets/images/dashboard/disabledUser.png")}
                                                />}
                                            </a>{" "}
                                            <Tooltip id="my-tooltip" />
                                          </li>
                                          {/* </> */}
                                        </ul>
                                      </li>






                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content={`ID ${userLev1right == 0
                                            ? "Not Found"
                                            : userLev1right
                                            } | L ${Findtotalleft(userLev1right)} | R ${Findtotalright(userLev1right)}`}
                                          data-tooltip-place="top"
                                        >
                                          {" "}

                                          {userLev1right != 0 && userLev1right != "Not Found" ?
                                            <img
                                              src={require("../../assets/images/dashboard/chat_men.png")}
                                            /> : <img
                                              src={require("../../assets/images/dashboard/disabledUser.png")}
                                            />}

                                        </a>{" "}
                                        <Tooltip id="my-tooltip" />

                                        {binaryTree &&
                                          binaryTree?.user &&
                                          binaryTree?.user?.data &&
                                          binaryTree?.user.data.map((item, index) => {
                                            if (
                                              userLevleft != 0 &&
                                              item.item.key != 0 &&
                                              item.item.key === userLev1right
                                            )
                                              return (
                                                <span style={{ display: "none" }}>
                                                  {(userLev1Lev2left = item.item.left)}{" "}
                                                  {(userLev1Lev2right = item.item.right)}
                                                </span>
                                              );
                                          })}

                                        <ul className="third_chat">
                                          {/* <> */}
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content={`ID ${userLev1Lev2left == 0
                                                ? "Not Found"
                                                : userLev1Lev2left
                                                } | L ${Findtotalleft(userLev1Lev2left)} | R ${Findtotalright(userLev1Lev2left)}`}
                                              data-tooltip-place="top"
                                            >
                                              {" "}

                                              {userLev1Lev2left != 0 && userLev1Lev2left != "Not Found" ?
                                                <img
                                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                                /> : <img
                                                  src={require("../../assets/images/dashboard/disabledUser.png")}
                                                />}

                                            </a>{" "}
                                            <Tooltip id="my-tooltip" />
                                          </li>

                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content={`ID ${userLev1Lev2right == 0
                                                ? "Not Found"
                                                : userLev1Lev2right
                                                } | L ${Findtotalleft(userLev1Lev2right)} | R ${Findtotalright(userLev1Lev2right)}`}
                                              data-tooltip-place="top"
                                            >
                                              {" "}

                                              {userLev1Lev2right != 0 && userLev1Lev2right != "Not Found" ?
                                                <img
                                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                                /> : <img
                                                  src={require("../../assets/images/dashboard/disabledUser.png")}
                                                />}

                                            </a>{" "}
                                            <Tooltip id="my-tooltip" />
                                          </li>
                                          {/* </> */}
                                        </ul>


                                      </li>
                                      {/* </> */}
                                    </ul>
                                  </>
                                </li>



                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`ID ${userLevright} | L ${Findtotalleft(userLevright)} | R ${Findtotalright(userLevright)}`}
                                    data-tooltip-place="top"
                                  >
                                    {" "}

                                    {userLevright != "Not Found" ?
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      /> : <img
                                        src={require("../../assets/images/dashboard/disabledUser.png")}
                                      />}

                                  </a>{" "}
                                  <Tooltip id="my-tooltip" />
                                  <>
                                    {binaryTree &&
                                      binaryTree?.user &&
                                      binaryTree?.user?.data &&
                                      binaryTree?.user.data.map((item, index) => {
                                        if (
                                          userLevright != 0 &&
                                          item.item.key != 0 &&
                                          item.item.key === userLevright
                                        )
                                          return (
                                            <span style={{ display: "none" }}>
                                              {(userLev2left = item.item.left)}{" "}
                                              {(userLev2right = item.item.right)}
                                            </span>
                                          );
                                      })}

                                    <ul className="third_chat">
                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content={`ID ${userLev2left == 0
                                            ? "Not Found"
                                            : userLev2left
                                            } | L ${Findtotalleft(userLev2left)} | R ${Findtotalright(userLev2left)}`}
                                          data-tooltip-place="top"
                                        >
                                          {" "}
                                          {userLev2left != 0 && userLev2left != "Not Found" ?
                                            <img
                                              src={require("../../assets/images/dashboard/chat_men.png")}
                                            /> : <img
                                              src={require("../../assets/images/dashboard/disabledUser.png")}
                                            />}


                                        </a>{" "}
                                        <Tooltip id="my-tooltip" />

                                        {binaryTree &&
                                          binaryTree?.user &&
                                          binaryTree?.user?.data &&
                                          binaryTree?.user.data.map((item, index) => {
                                            if (
                                              userLevleft != 0 &&
                                              item.item.key != 0 &&
                                              item.item.key === userLev2left
                                            )
                                              return (
                                                <span style={{ display: "none" }}>
                                                  {(userLev2Lev1left = item.item.left)}{" "}
                                                  {(userLev2Lev1right = item.item.right)}
                                                </span>
                                              );
                                          })}
                                        <ul className="third_chat">
                                          {/* <> */}
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content={`ID ${userLev2Lev1left == 0
                                                ? "Not Found"
                                                : userLev2Lev1left
                                                } | L ${Findtotalleft(userLev2Lev1left)} | R ${Findtotalright(userLev2Lev1left)}`}
                                              data-tooltip-place="top"
                                            >

                                              {userLev2Lev1left != 0 && userLev2Lev1left != "Not Found" ?
                                                <img
                                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                                /> : <img
                                                  src={require("../../assets/images/dashboard/disabledUser.png")}
                                                />}

                                              {" "}

                                            </a>{" "}
                                            <Tooltip id="my-tooltip" />
                                          </li>

                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content={`ID ${userLev2Lev1right == 0
                                                ? "Not Found"
                                                : userLev2Lev1right
                                                } | L ${Findtotalleft(userLev2Lev1right)} | R ${Findtotalright(userLev2Lev1right)}`}
                                              data-tooltip-place="top"
                                            >
                                              {" "}
                                              {userLev2Lev1right != 0 && userLev2Lev1right != "Not Found" ?
                                                <img
                                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                                /> : <img
                                                  src={require("../../assets/images/dashboard/disabledUser.png")}
                                                />}

                                            </a>{" "}
                                            <Tooltip id="my-tooltip" />
                                          </li>
                                          {/* </> */}
                                        </ul>



                                      </li>

                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content={`ID ${userLev2right == 0
                                            ? "Not Found"
                                            : userLev2right
                                            } | L ${Findtotalleft(userLev2right)} | R ${Findtotalright(userLev2right)}`}
                                          data-tooltip-place="top"
                                        >
                                          {" "}
                                          {userLev2right != 0 && userLev2right != "Not Found" ?
                                            <img
                                              src={require("../../assets/images/dashboard/chat_men.png")}
                                            /> : <img
                                              src={require("../../assets/images/dashboard/disabledUser.png")}
                                            />}

                                        </a>{" "}
                                        <Tooltip id="my-tooltip" />

                                        {binaryTree &&
                                          binaryTree?.user &&
                                          binaryTree?.user?.data &&
                                          binaryTree?.user.data.map((item, index) => {
                                            if (
                                              userLevleft != 0 &&
                                              item.item.key != 0 &&
                                              item.item.key === userLev2right
                                            )
                                              return (
                                                <span style={{ display: "none" }}>
                                                  {(userLev2Lev2left = item.item.left)}{" "}
                                                  {(userLev2Lev2right = item.item.right)}
                                                </span>
                                              );
                                          })}
                                        <ul className="third_chat">

                                          {/* <> */}
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content={`ID ${userLev2Lev2left == 0
                                                ? "Not Found"
                                                : userLev2Lev2left
                                                }  | L ${Findtotalleft(userLev2Lev2left)} | R ${Findtotalright(userLev2Lev2left)} `}
                                              data-tooltip-place="top"
                                            >
                                              {" "}
                                              {userLev2Lev2left != 0 && userLev2Lev2left != "Not Found" ?
                                                <img
                                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                                /> : <img
                                                  src={require("../../assets/images/dashboard/disabledUser.png")}
                                                />}

                                            </a>{" "}
                                            <Tooltip id="my-tooltip" />
                                          </li>

                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content={`ID ${userLev2Lev2right == 0
                                                ? "Not Found"
                                                : userLev2Lev2right
                                                } | L ${Findtotalleft(userLev2Lev2right)} | R ${Findtotalright(userLev2Lev2right)}`}
                                              data-tooltip-place="top"
                                            >
                                              {" "}
                                              {userLev2Lev2right != 0 && userLev2Lev2right != "Not Found" ?
                                                <img
                                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                                /> : <img
                                                  src={require("../../assets/images/dashboard/disabledUser.png")}
                                                />}

                                            </a>{" "}
                                            <Tooltip id="my-tooltip" />
                                          </li>
                                          {/* </> */}
                                        </ul>
                                      </li>
                                    </ul>
                                  </>
                                </li>
                              </ul>
                            </li>
                          );
                      })}
                  </ul>
                ) : (
                  <ul>
                    {isvalid ? (
                      <h3>UserID Not Found</h3>
                    ) : (
                      binaryTree &&
                      binaryTree?.user &&
                      binaryTree?.user?.data &&
                      binaryTree?.user.data.map((item, index) => {
                        if (item.item.key == searchId)
                          return (
                            <li>
                            <span style={{ display: "none" }}>
                              {
                                (userLevleft =
                                  item.item.left == 0
                                    ? "Not Found"
                                    : item.item.left)
                              }{" "}
                              {
                                (userLevright =
                                  item.item.right == 0
                                    ? "Not Found"
                                    : item.item.right)
                              }
                            </span>
                            <a
                              href="javascript:void(0)"
                              className="root_img"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={`ID ${item.item.key || "Not Found"
                                }  | L ${Findtotalleft(item.item.key)} | R ${Findtotalright(item.item.key)}`}
                              data-tooltip-place="top"
                            >
                              <img
                                src={require("../../assets/images/dashboard/men_2.png")}
                              />
                            </a>
                            <Tooltip id="my-tooltip" />
                            <ul className="second_chat">
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={`ID ${userLevleft} | L ${Findtotalleft(userLevleft)} | R ${Findtotalright(userLevleft)}`}
                                  data-tooltip-place="top"
                                >
                                  {" "}
                                  {userLevleft != "Not Found" ?
                                    <img
                                      src={require("../../assets/images/dashboard/chat_men.png")}
                                    /> : <img
                                      src={require("../../assets/images/dashboard/disabledUser.png")}
                                    />}
                                </a>

                                <Tooltip id="my-tooltip" />

                                <>
                                  {binaryTree &&
                                    binaryTree?.user &&
                                    binaryTree?.user?.data &&
                                    binaryTree?.user.data.map((item, index) => {
                                      if (
                                        userLevleft != 0 &&
                                        item.item.key != 0 &&
                                        item.item.key === userLevleft
                                      )
                                        return (
                                          <span style={{ display: "none" }}>
                                            {(userLev1left = item.item.left)}{" "}
                                            {(userLev1right = item.item.right)}
                                          </span>
                                        );
                                    })}
                                  <ul className="third_chat">
                                    {/* <> */}
                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={`ID ${userLev1left == 0
                                          ? "Not Found"
                                          : userLev1left
                                          } | L ${Findtotalleft(userLev1left)} | R ${Findtotalright(userLev1left)}`}
                                        data-tooltip-place="top"
                                      >
                                        {" "}
                                        {userLev1left != 0 && userLev1left != "Not Found" ?
                                          <img
                                            src={require("../../assets/images/dashboard/chat_men.png")}
                                          /> : <img
                                            src={require("../../assets/images/dashboard/disabledUser.png")}
                                          />}
                                      </a>{" "}
                                      <Tooltip id="my-tooltip" />




                                      {binaryTree &&
                                        binaryTree?.user &&
                                        binaryTree?.user?.data &&
                                        binaryTree?.user.data.map((item, index) => {
                                          if (
                                            userLevleft != 0 &&
                                            item.item.key != 0 &&
                                            item.item.key === userLev1left
                                          )
                                            return (
                                              <span style={{ display: "none" }}>
                                                {(userLev1Lev1left = item.item.left)}{" "}
                                                {(userLev1Lev1right = item.item.right)}
                                              </span>
                                            );
                                        })}
                                      <ul className="third_chat">
                                        {/* <> */}
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`ID ${userLev1Lev1left == 0
                                              ? "Not Found"
                                              : userLev1Lev1left
                                              }  | L ${Findtotalleft(userLev1Lev1left)} | R ${Findtotalright(userLev1Lev1left)} `}
                                            data-tooltip-place="top"
                                          >
                                            {" "}


                                            {userLev1Lev1left != 0 && userLev1Lev1left != "Not Found" ?
                                              <img
                                                src={require("../../assets/images/dashboard/chat_men.png")}
                                              /> : <img
                                                src={require("../../assets/images/dashboard/disabledUser.png")}
                                              />}

                                          </a>{" "}
                                          <Tooltip id="my-tooltip" />
                                        </li>

                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`ID ${userLev1Lev1right == 0
                                              ? "Not Found"
                                              : userLev1Lev1right
                                              } | L ${Findtotalleft(userLev1Lev1right)} | R ${Findtotalright(userLev1Lev1right)} `}
                                            data-tooltip-place="top"
                                          >
                                            {" "}

                                            {userLev1Lev1right != 0 && userLev1Lev1right != "Not Found" ?
                                              <img
                                                src={require("../../assets/images/dashboard/chat_men.png")}
                                              /> : <img
                                                src={require("../../assets/images/dashboard/disabledUser.png")}
                                              />}
                                          </a>{" "}
                                          <Tooltip id="my-tooltip" />
                                        </li>
                                        {/* </> */}
                                      </ul>
                                    </li>






                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={`ID ${userLev1right == 0
                                          ? "Not Found"
                                          : userLev1right
                                          } | L ${Findtotalleft(userLev1right)} | R ${Findtotalright(userLev1right)}`}
                                        data-tooltip-place="top"
                                      >
                                        {" "}

                                        {userLev1right != 0 && userLev1right != "Not Found" ?
                                          <img
                                            src={require("../../assets/images/dashboard/chat_men.png")}
                                          /> : <img
                                            src={require("../../assets/images/dashboard/disabledUser.png")}
                                          />}

                                      </a>{" "}
                                      <Tooltip id="my-tooltip" />

                                      {binaryTree &&
                                        binaryTree?.user &&
                                        binaryTree?.user?.data &&
                                        binaryTree?.user.data.map((item, index) => {
                                          if (
                                            userLevleft != 0 &&
                                            item.item.key != 0 &&
                                            item.item.key === userLev1right
                                          )
                                            return (
                                              <span style={{ display: "none" }}>
                                                {(userLev1Lev2left = item.item.left)}{" "}
                                                {(userLev1Lev2right = item.item.right)}
                                              </span>
                                            );
                                        })}

                                      <ul className="third_chat">
                                        {/* <> */}
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`ID ${userLev1Lev2left == 0
                                              ? "Not Found"
                                              : userLev1Lev2left
                                              } | L ${Findtotalleft(userLev1Lev2left)} | R ${Findtotalright(userLev1Lev2left)}`}
                                            data-tooltip-place="top"
                                          >
                                            {" "}

                                            {userLev1Lev2left != 0 && userLev1Lev2left != "Not Found" ?
                                              <img
                                                src={require("../../assets/images/dashboard/chat_men.png")}
                                              /> : <img
                                                src={require("../../assets/images/dashboard/disabledUser.png")}
                                              />}

                                          </a>{" "}
                                          <Tooltip id="my-tooltip" />
                                        </li>

                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`ID ${userLev1Lev2right == 0
                                              ? "Not Found"
                                              : userLev1Lev2right
                                              } | L ${Findtotalleft(userLev1Lev2right)} | R ${Findtotalright(userLev1Lev2right)}`}
                                            data-tooltip-place="top"
                                          >
                                            {" "}

                                            {userLev1Lev2right != 0 && userLev1Lev2right != "Not Found" ?
                                              <img
                                                src={require("../../assets/images/dashboard/chat_men.png")}
                                              /> : <img
                                                src={require("../../assets/images/dashboard/disabledUser.png")}
                                              />}

                                          </a>{" "}
                                          <Tooltip id="my-tooltip" />
                                        </li>
                                        {/* </> */}
                                      </ul>


                                    </li>
                                    {/* </> */}
                                  </ul>
                                </>
                              </li>



                              <li>
                                <a
                                  href="javascript:void(0)"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={`ID ${userLevright} | L ${Findtotalleft(userLevright)} | R ${Findtotalright(userLevright)}`}
                                  data-tooltip-place="top"
                                >
                                  {" "}

                                  {userLevright != "Not Found" ?
                                    <img
                                      src={require("../../assets/images/dashboard/chat_men.png")}
                                    /> : <img
                                      src={require("../../assets/images/dashboard/disabledUser.png")}
                                    />}

                                </a>{" "}
                                <Tooltip id="my-tooltip" />
                                <>
                                  {binaryTree &&
                                    binaryTree?.user &&
                                    binaryTree?.user?.data &&
                                    binaryTree?.user.data.map((item, index) => {
                                      if (
                                        userLevright != 0 &&
                                        item.item.key != 0 &&
                                        item.item.key === userLevright
                                      )
                                        return (
                                          <span style={{ display: "none" }}>
                                            {(userLev2left = item.item.left)}{" "}
                                            {(userLev2right = item.item.right)}
                                          </span>
                                        );
                                    })}

                                  <ul className="third_chat">
                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={`ID ${userLev2left == 0
                                          ? "Not Found"
                                          : userLev2left
                                          } | L ${Findtotalleft(userLev2left)} | R ${Findtotalright(userLev2left)}`}
                                        data-tooltip-place="top"
                                      >
                                        {" "}
                                        {userLev2left != 0 && userLev2left != "Not Found" ?
                                          <img
                                            src={require("../../assets/images/dashboard/chat_men.png")}
                                          /> : <img
                                            src={require("../../assets/images/dashboard/disabledUser.png")}
                                          />}


                                      </a>{" "}
                                      <Tooltip id="my-tooltip" />

                                      {binaryTree &&
                                        binaryTree?.user &&
                                        binaryTree?.user?.data &&
                                        binaryTree?.user.data.map((item, index) => {
                                          if (
                                            userLevleft != 0 &&
                                            item.item.key != 0 &&
                                            item.item.key === userLev2left
                                          )
                                            return (
                                              <span style={{ display: "none" }}>
                                                {(userLev2Lev1left = item.item.left)}{" "}
                                                {(userLev2Lev1right = item.item.right)}
                                              </span>
                                            );
                                        })}
                                      <ul className="third_chat">
                                        {/* <> */}
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`ID ${userLev2Lev1left == 0
                                              ? "Not Found"
                                              : userLev2Lev1left
                                              } | L ${Findtotalleft(userLev2Lev1left)} | R ${Findtotalright(userLev2Lev1left)}`}
                                            data-tooltip-place="top"
                                          >

                                            {userLev2Lev1left != 0 && userLev2Lev1left != "Not Found" ?
                                              <img
                                                src={require("../../assets/images/dashboard/chat_men.png")}
                                              /> : <img
                                                src={require("../../assets/images/dashboard/disabledUser.png")}
                                              />}

                                            {" "}

                                          </a>{" "}
                                          <Tooltip id="my-tooltip" />
                                        </li>

                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`ID ${userLev2Lev1right == 0
                                              ? "Not Found"
                                              : userLev2Lev1right
                                              } | L ${Findtotalleft(userLev2Lev1right)} | R ${Findtotalright(userLev2Lev1right)}`}
                                            data-tooltip-place="top"
                                          >
                                            {" "}
                                            {userLev2Lev1right != 0 && userLev2Lev1right != "Not Found" ?
                                              <img
                                                src={require("../../assets/images/dashboard/chat_men.png")}
                                              /> : <img
                                                src={require("../../assets/images/dashboard/disabledUser.png")}
                                              />}

                                          </a>{" "}
                                          <Tooltip id="my-tooltip" />
                                        </li>
                                        {/* </> */}
                                      </ul>



                                    </li>

                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={`ID ${userLev2right == 0
                                          ? "Not Found"
                                          : userLev2right
                                          } | L ${Findtotalleft(userLev2right)} | R ${Findtotalright(userLev2right)}`}
                                        data-tooltip-place="top"
                                      >
                                        {" "}
                                        {userLev2right != 0 && userLev2right != "Not Found" ?
                                          <img
                                            src={require("../../assets/images/dashboard/chat_men.png")}
                                          /> : <img
                                            src={require("../../assets/images/dashboard/disabledUser.png")}
                                          />}

                                      </a>{" "}
                                      <Tooltip id="my-tooltip" />

                                      {binaryTree &&
                                        binaryTree?.user &&
                                        binaryTree?.user?.data &&
                                        binaryTree?.user.data.map((item, index) => {
                                          if (
                                            userLevleft != 0 &&
                                            item.item.key != 0 &&
                                            item.item.key === userLev2right
                                          )
                                            return (
                                              <span style={{ display: "none" }}>
                                                {(userLev2Lev2left = item.item.left)}{" "}
                                                {(userLev2Lev2right = item.item.right)}
                                              </span>
                                            );
                                        })}
                                      <ul className="third_chat">

                                        {/* <> */}
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`ID ${userLev2Lev2left == 0
                                              ? "Not Found"
                                              : userLev2Lev2left
                                              }  | L ${Findtotalleft(userLev2Lev2left)} | R ${Findtotalright(userLev2Lev2left)} `}
                                            data-tooltip-place="top"
                                          >
                                            {" "}
                                            {userLev2Lev2left != 0 && userLev2Lev2left != "Not Found" ?
                                              <img
                                                src={require("../../assets/images/dashboard/chat_men.png")}
                                              /> : <img
                                                src={require("../../assets/images/dashboard/disabledUser.png")}
                                              />}

                                          </a>{" "}
                                          <Tooltip id="my-tooltip" />
                                        </li>

                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`ID ${userLev2Lev2right == 0
                                              ? "Not Found"
                                              : userLev2Lev2right
                                              } | L ${Findtotalleft(userLev2Lev2right)} | R ${Findtotalright(userLev2Lev2right)}`}
                                            data-tooltip-place="top"
                                          >
                                            {" "}
                                            {userLev2Lev2right != 0 && userLev2Lev2right != "Not Found" ?
                                              <img
                                                src={require("../../assets/images/dashboard/chat_men.png")}
                                              /> : <img
                                                src={require("../../assets/images/dashboard/disabledUser.png")}
                                              />}

                                          </a>{" "}
                                          <Tooltip id="my-tooltip" />
                                        </li>
                                        {/* </> */}
                                      </ul>
                                    </li>
                                  </ul>
                                </>
                              </li>
                            </ul>
                          </li>
                          );
                      })
                    )}
                  </ul>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default TreeDisplay;

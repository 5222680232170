import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import PropagateLoader from "react-spinners/PropagateLoader";

import TokenABI from "../ABI/TokenContractAbi.json";
import ContractAbi from "../ABI/MLMContractAbi.json";

import config from "../config/index";
import { convert } from "../helper/convert.js";
import { useDispatch, useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getTransactionInfo } from "../helper/checkhash";
import Web3 from "web3";
import ConnectWallet from "../components/ConnectWallet.js";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};


export default function Joinnow(props) {
  const walletConnection = useSelector((state) => state.walletConnection);

  const { id, type } = useParams();
  const [loader, setLoader] = useState(false);
  const [referral, setreferral] = useState(id == undefined ? "" : id);

  const [tokenprice, settokenprice] = useState(0);
  const [firstPlan, setfirstPlan] = useState(0);
  const [option, setoption] = useState(type == undefined ? "" : type);
  const [poolInfo, setPoolInfo] = useState([]);

  const [selectedPlan, setselectedPlan] = useState(1);

  useEffect(() => {
    checkPage();
    getPriceData();
    getPoolDetails();

  }, [walletConnection.address, selectedPlan]);

  const checkPage = () => {
    if (walletConnection && walletConnection?.userdetails?.isExist) {
      window.location.href = "/dashboard";
    }
  };

  const handleChange = (e) => {
    var { name, value } = e.target;
    console.log(value, "value");
    setoption(value == "Left" ? 1 : 2);
  };

  const joinPlan = async () => {
    try {
      if (!walletConnection.address) {
        toastAlert("error", "Connect Your Wallet");
        return false;
      }
      setLoader(true);

      var web3 = new Web3(walletConnection.web3);
      if (referral == "") {
        toastAlert("error", "Referral Address Required");
        setLoader(false);
        return false;
      }
      if (!web3.utils.isAddress(referral)) {
        toastAlert("error", "Referral ID is not valid");
        setLoader(false);
        return false;
      }

      if (option == 0) {
        toastAlert("error", "Position Required");
        setLoader(false);
        return false;
      }


      const ContractToken = new web3.eth.Contract(
        TokenABI,
        config.TokenContract
      );
      var contractcall = new web3.eth.Contract(ContractAbi, config.Contract);
      let firstPlan = await contractcall.methods.poolInfo(Number(selectedPlan)).call();

      let getTokenprice = await contractcall.methods.tokenPrice().call();

      let tokenprice = getTokenprice / 1e18;

      let planAmount =
        Number(firstPlan.poolAmount * tokenprice) / config.decimalVal;

      let tokenBal = await ContractToken.methods
        .balanceOf(walletConnection.address)
        .call();

      if (Number(planAmount) > Number(tokenBal / config.decimalVal)) {
        toastAlert("error", "Your YEZB balance is low");
        setLoader(false);
        return false;
      }

      var amt = ((Number(planAmount) + 10) * 1e18).toString()
      amt = await convert(amt);

      let estimateGas = await ContractToken.methods
        .approve(config.Contract, amt)
        .estimateGas({ from: walletConnection.address })
        .catch((err) => console.log(err));

      let gasPriceUsdt = await web3.eth.getGasPrice();
      let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;
      var balance = await web3.eth.getBalance(walletConnection.address);

      if (estimateGasFee > balance) {
        toastAlert("error", "Your BNB balance is low");
        setLoader(false);
        return false;
      }
      let referralcheck = await contractcall.methods.users(referral).call();

      let Usercheck = await contractcall.methods
        .users(walletConnection.address)
        .call();

      if (Usercheck.isExist) {
        toastAlert("error", "User already exits");
        setLoader(false);
        return false;
      }

      if (!referralcheck.isExist) {
        toastAlert("error", "Referral not exits");
        setLoader(false);
        return false;
      }



      await ContractToken.methods
        .approve(config.Contract, amt)
        .send({ from: walletConnection.address })
        .then(async (res) => {
          await contractcall.methods
            .joinPlan(referral, option, Number(selectedPlan))
            .send({ from: walletConnection.address })
            .then(async (res) => {
              const result = await getTransactionInfo(res.transactionHash);
              if (result) {
                toastAlert("success", "Join Successfully");
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
                setLoader(false);
              }
            });
        });
    } catch (err) {
      console.log(err);
      toastAlert("error", "Transaction Rejected");
      setLoader(false);
    }
  };

  const getPriceData = async () => {
    try {
      if (config.rpcurl || walletConnection?.web3) {
        var web3 = new Web3(config.rpcurl || walletConnection?.web3);
        const Contract = new web3.eth.Contract(ContractAbi, config.Contract);
        let tokenPrice = await Contract.methods.tokenPrice().call();
        let firstPlan = await Contract.methods.poolInfo(Number(selectedPlan)).call();

        settokenprice(tokenPrice / 1e18);
        setfirstPlan(firstPlan.poolAmount / 1e18);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPoolDetails = async () => {
    try {
      setLoader(true);

      const web3 = new Web3(config.rpcurl || walletConnection?.web3);
      const contract = new web3.eth.Contract(ContractAbi, config.Contract);
      const poolLength = await contract.methods.getPoolLenght().call();

      const pools = await Promise.all(
        Array.from({ length: poolLength }, (_, i) => i).map(async (i) => {
          const poolData = await contract.methods.poolInfo(i).call();
          return {
            poolNo: i,
            poolAmount: Number(poolData.poolAmount) / 1e18,
          };
        })
      );
      console.log(pools, "pools");

      setPoolInfo(pools);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };


  const handleClear = async () => {
    settokenprice(0)
    setfirstPlan(0)
  }


  return (
    <div>
      <Navbar />
      <div className="joinnow">
        <div className="container">
          <div className="row">
            <div className="col-lg-12  mx-auto">
              <div className="box">
                <h2 className="h2tag mb-4 text-center w-100">Joinnow</h2>

                <div className="join-now-sides">
                  <div className="left-side-joinnow">
                    <label className="mb-2">Referral ID</label>
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Referral ID"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={referral}
                        onChange={(e) => setreferral(e.target.value)}
                        disabled={id == undefined ? false : true}
                      />
                    </div>
                    <label class="form-label d-block">Choose position</label>
                    <div className="flex-option">
                      <div class="option">
                        <input
                          // class="form-check-input"
                          type="radio"
                          name="option"
                          id="inlineRadio1"
                          value="Left"
                          checked={option == "1" ? true : false}
                          onChange={handleChange}
                          disabled={type == undefined ? false : true}
                        />
                        <label for="inlineRadio1">Left</label>
                      </div>
                      <div class="option">
                        <input
                          // class="form-check-input"
                          type="radio"
                          name="option"
                          id="inlineRadio2"
                          value="Right"
                          checked={option == "2" ? true : false}
                          onChange={handleChange}
                          disabled={type == undefined ? false : true}
                        />
                        <label for="inlineRadio2"> Right</label>
                      </div>
                    </div>
                    {/* <p className="default-address">
                      Default Address : {config.defalutAddr}
                    </p> */}

                  </div>
                  <div className="right-side-joinnow">
                    <label class="form-label d-block">Select Package</label>
                    <div className="joinnow-btn-head">
                      {poolInfo?.map((item, index) => {
                        if (item.poolNo != 0) {
                          return (
                            <button key={index} className={`joinnow-btn ${selectedPlan == item.poolNo ? "select" : ""}`} onClick={() => setselectedPlan(item.poolNo)}>${item.poolAmount}</button>
                          )
                        }

                      })
                      }


                    </div>
                  </div>
                </div>

                <div className="text-start">
                  {!walletConnection?.address ? <ConnectWallet /> :
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#joinConfirmation_popUp"
                    >
                      Submit
                    </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        loader && (
          <div className="loadBefore">
            <PropagateLoader
              color={"#eba6fb"}
              loading={loader}
              cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )
      }

      <Footer />

      <div
        className="modal fade primary_modal"
        id="joinConfirmation_popUp"
        tabIndex={-1}
        aria-labelledby="joinConfirmation_popUp"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="joinConfirmation_popUp">
                Info
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClear}
              ></button>
            </div>

            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {/* <button onClick={() => upgradePlan(SelectedPlan)} className="primary_btn mt-1">
                                        Confirm
                                    </button> */}

                  <div className="text-center popupPara">
                    TO JOIN THIS PLAN NEED {(firstPlan * tokenprice).toFixed(4)}{" "}
                    YEZB TOKEN
                  </div>

                  <button
                    className="primary_btn mt-4 mx-auto d-block"
                    // onClick={() => handleWithdraw(UserData?.totalIncome)}
                    onClick={joinPlan}
                    disabled={loader}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
}

import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import {
  bsc,
  bscTestnet,
} from "viem/chains";
import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { infuraProvider } from "wagmi/providers/infura";

import config from "../src/config/index.js";

import Home from './pages/home.js';
import Joinnow from "./pages/joinnow.js";
import DashBoard from "./pages/dashboard.js";
import DashBoardCheck from "./pages/dashboardCheck.js"
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet],
  [publicProvider()],
);

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains, shimDisconnect: true }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: config.projectId,
        version: "2",
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/join-now", element: <Joinnow /> },
    { path: "/join-now/:id/:type", element: <Joinnow /> },
    { path: "/dashboard", element: <DashBoard /> },
    { path: "/dashboard-check/:address", element: <DashBoardCheck /> },

  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Router basename="/">
        <WagmiConfig config={wconfigInitial}>
          <App />
        </WagmiConfig>
      </Router>
    </Provider>
  );
};

export default AppWrapper;

import * as React from "react";
import { type WalletClient, useWalletClient } from "wagmi";
import { BrowserProvider, JsonRpcSigner } from "ethers";

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address
  };

  // console.log(network, "networknetworknetwork");
  const provider = new BrowserProvider(transport, network);
  var signer = {};
  if (account && account.address) {
    signer = new JsonRpcSigner(provider, account.address);
  }

  return {
    signer,
    transport
  };
}

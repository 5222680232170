export function displayFormattedAddress(address, value1, value2) {
  const formattedAddress = `0x${address.slice(2, value1)}...${address.slice(
    -value2
  )}`;
  return formattedAddress;
}
export const validPositive = (e) => {
  if (
    new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
    (e.target.value = "")
  ) {
    e.preventDefault();
  }
};


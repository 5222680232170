import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { customStyles } from "../../helper/paginationcolor";

function PackageHistory({ userPlanHist, userTradInceHistory }) {
  console.log(userPlanHist, userTradInceHistory, "userPlanHist");

  const checkPlanData = useMemo(
    () => (ids) => {
      console.log(ids, "idsids");
      if (!Array.isArray(ids)) {
        return "0.0000";
      }

      const totalIncentive = ids.reduce((sum, id) => {
        console.log(id, "id");
        const finalData = userTradInceHistory?.find(
          (el) => el.userplanId == id
        );
        return sum + (finalData ? parseFloat(finalData.incentive) : 0);
      }, 0);
      console.log(totalIncentive, "totalIncentive");
      return totalIncentive.toFixed(4);
    },
    [userTradInceHistory]
  );

  const checkPlanStatus = useMemo(
    () => (date) => {
      const today = new Date();
      const createdAt = new Date(date);
      const diffTime = Math.abs(today - createdAt);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays < 500 ? "Active" : "Inactive";
    },
    []
  );

  const result = userPlanHist?.reduce((acc, transaction) => {
    const { planId, planAmount, _id, createdAt } = transaction;

    if (!acc[planId]) {
      acc[planId] = {
        planId: planId,
        totalPlanAmount: 0,
        count: 0,
        ids: [],
        createdAt: "",
      };
    }

    acc[planId].totalPlanAmount += planAmount;
    acc[planId].count += 1;
    acc[planId].ids.push(_id);

    // Update createdAt if the current transaction's createdAt is later
    if (
      !acc[planId].createdAt ||
      new Date(createdAt) > new Date(acc[planId].createdAt)
    ) {
      acc[planId].createdAt = createdAt;
    }

    return acc;
  }, {});

  const finalResult = Object?.values(result || {});

  const columns = useMemo(
    () => [
      // {
      //     name: 'ID',
      //     selector: (row, index) => index + 1,
      //     width: '10%',
      // },
      {
        name: "Date/Time",
        width: "250px",
        selector: (row) => moment(row?.createdAt).format("MMMM Do YYYY, h:mm"),
      },
      {
        name: "Package",
        selector: (row) => row.planId + 1,
        width: "140px",
      },
      {
        width: "190px",
        name: "Package Amount",
        selector: (row) => row.totalPlanAmount,
      },
      {
        name: "Rejoin Count",
        width: "180px",
        selector: (row) => row.count - 1,
      },
      {
        name: "Incentive",
        width: "180px",
        selector: (row) => checkPlanData(row.ids),
      },
      {
        name: "Status",
        selector: (row) => checkPlanStatus(row.createdAt),
      },
    ],
    [checkPlanData, checkPlanStatus]
  );

  return (
    <DataTable
      columns={columns}
      data={finalResult}
      className="dash_board_table mt-3"
      pagination
      paginationPerPage={5}
      paginationRowsPerPageOptions={[5, 10]}
      customStyles={customStyles}
    />
  );
}

export default PackageHistory;


import { CheckTransHash } from "../Action/api.Action"

const RETRY_DELAY = 2000; // Delay in milliseconds

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getTransactionInfo = async (hash) => {
    while (true) {
        try {
            const res = await CheckTransHash({ hash });
            if (res.data.status) {
                return true;
            } else {
                console.log("Transaction not found, retrying...");
                await sleep(RETRY_DELAY);
            }
        } catch (error) {
            console.error("Error fetching transaction info:", error);
            throw error;
        }
    }
};

export const getTransactionwithdraw = async (hash) => {
    let type = "withdraw"
    while (true) {
        try {
            const res = await CheckTransHash({ hash, type });
            if (res.data.status) {
                return true;
            } else {
                console.log("Transaction not found, retrying...");
                await sleep(RETRY_DELAY);
            }
        } catch (error) {
            console.error("Error fetching transaction info:", error);
            throw error;
        }
    }
};